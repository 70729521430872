<template>
  <v-container fluid class="flex-wrap flex content-center h-screen justify-center">
    <v-card class="w-full md:w-1/4 ">
      <v-card-text>
        Вход
      </v-card-text>
      <v-card-text>
        <v-text-field label="Телефон" v-model="username">
        </v-text-field>
        <v-text-field type="password" label="Пароль" v-model="password">
        </v-text-field>
        <span v-if="error" class="text-red-300">
          {{error}}
        </span>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
<!--        <v-btn @click="google">Google</v-btn>-->
        <v-btn @click="submit()">Войти</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import router from "@/router";
import {store} from "@/store";

export default {
  name: "Login",
  data: () => ({
    username: "",
    password: "",
    error: ""
  }),
  methods: {
    submit(){
      store.dispatch("signInWithEmailAndPassword", {username: this.username, password: this.password}).then( async (access_token) => {
        //console.log(access_token)
        await store.commit('saveToken', access_token)
        await store.commit('setUser', await store.dispatch('getCurrentUser'))
        await router.push({ name: 'App' })
      }).catch(() => {
        //console.log({error})
        this.error = "Wrong login or password"
      })
    },
    google(){
      store.dispatch("signInWithGoogle").then( () => {
        //console.log({data})
        router.push({ name: 'App' })
      }).catch(error => {
        //console.log({error})
        this.error = error
      })
    }
  }
}
</script>

<style scoped>

</style>
